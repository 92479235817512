
import { Component, Vue, Prop } from "vue-property-decorator";
import RadioGroup from "@/components/RadioGroup.vue";
import RatesCalculatorCalculationFactors from "@/components/RatesCalculatorCalculationFactors.vue";
import RatesCalculatorResult from "@/components/RatesCalculatorResult.vue";
import PasswordDialog from "@/components/PasswordDialog.vue";
import { Rate, User } from "@/interfaces";
import Rates from "@/data/rates.json";
import * as _ from "lodash";

@Component({
  components: {
    RadioGroup,
    RatesCalculatorCalculationFactors,
    RatesCalculatorResult,
    PasswordDialog
  }
})
export default class RatesCalculator extends Vue {
  protected monthsCount: number = 4;
  protected percentage: number = 80;
  protected canShowCalculation: boolean = true;
  protected authenticated: boolean = false;
  protected rate: Rate | null = null;
  protected rates: Rate[] = [];

  protected handleContractTypeChange(value: number) {
    if (value == 2) {
      this.canShowCalculation = false;
    } else {
      this.canShowCalculation = true;
    }
  }

  protected handleFactorsChange(monthsCount: number, percentage: number): void {
    this.monthsCount = monthsCount;
    this.percentage = percentage;
  }

  protected handleAuthentication(user: User): void {
    this.authenticated = true;
    let rate: Rate | null = this.findRateByUser(user);
    if (rate) {
      this.rate = rate;
    }
  }

  protected findRateByUser(user: User): Rate | null {
    let rates: Rate[] = _.filter(this.rates, { id: user.priceGroup });
    if (rates.length > 0) {
      return rates[0];
    }
    return null;
  }

  public mounted(): void {
    this.rates = Rates.rates;
  }
}
