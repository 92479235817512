
import { Component, Vue, Prop } from "vue-property-decorator";
const wNumb = require("wnumb");
import { gsap } from "gsap";
import { Rate } from "@/interfaces";

@Component
export default class RatesCalculatorResult extends Vue {
  @Prop(Number) monthsCount!: number;
  @Prop(Number) workloadPercent!: number;
  @Prop(Object) rate!: Rate;
  protected hourlyRate: number = 20;
  protected currencyFormatter!: any;
  protected costsTotal: number = 0;
  protected costsPerMonth: number = 0;

  get hourlyRateCalculated(): string {
    if (this.baseRate) {
      gsap.to(this.$data, 0.3, { hourlyRate: this.calculateHourlyRate() });
      return this.hourlyRate.toFixed(0);
    } else {
      return "XX";
    }
  }

  get workloadDays(): number {
    return 20 * (this.workloadPercent / 100);
  }

  get workloadHours(): number {
    return this.workloadDays * 8;
  }

  get costsPerMonthCalculated(): number | string {
    if (this.baseRate) {
      gsap.to(this.$data, 0.3, {
        costsPerMonth: this.hourlyRate * this.workloadHours
      });
      return this.costsPerMonth;
    } else {
      return "X.XXX";
    }
  }

  get costsTotalCalculated(): number | string {
    if (this.baseRate) {
      gsap.to(this.$data, 0.3, {
        costsTotal: this.costsPerMonth * this.monthsCount
      });
      return this.costsTotal;
    } else {
      return "XX.XXX";
    }
  }

  get baseRate(): Rate | null {
    if (this.rate) {
      return this.rate;
    }
    return null;
  }

  protected formatCurrency(value: number | string): string {
    if (typeof value == "number") {
      return this.currencyFormatter.to(value);
    } else {
      return value;
    }
  }

  protected calculateHourlyRate(): number | string {
    let rate: number | string = "XX";
    if (this.baseRate) {
      rate = this.baseRate.base;
      switch (this.monthsCount) {
        case 2:
          rate = this.reduceRateAfterWorkload(rate);
          break;
        case 3:
          rate = this.reduceRateAfterWorkload(rate - 5);
          break;
        case 4:
          rate = this.reduceRateAfterWorkload(rate - 10);
          break;
        case 5:
          rate = this.reduceRateAfterWorkload(rate - 15);
          break;
        case 6:
          rate = this.reduceRateAfterWorkload(rate - 15);
          break;
      }
    }
    return rate;
  }

  protected reduceRateAfterWorkload(rate: number): number {
    switch (this.workloadPercent) {
      case 40:
        break;
      case 60:
        rate = rate - 5;
        break;
      case 80:
        rate = rate - 10;
        break;
      case 100:
        rate = rate - 15;
        break;
    }
    return rate;
  }

  public created(): void {
    this.currencyFormatter = wNumb({
      mark: ",",
      decimals: 0,
      thousand: "."
    });
  }
}
