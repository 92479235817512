
import { Component, Vue, Prop } from "vue-property-decorator";
import PasswordSegment from "@/components/PasswordSegment.vue";
import { PasswordSegmentObject, User } from "@/interfaces";
import * as _ from "lodash";
import Users from "@/data/users.json";

@Component({
  components: {
    PasswordSegment
  }
})
export default class PasswordDialog extends Vue {
  protected currentSegment: number = 0;
  protected passwordSegments: PasswordSegmentObject[] = [];
  protected fields: any[] = [];
  protected users: User[] = [];
  protected showContact: boolean = false;
  protected shake: boolean = false;

  protected handleSet(value: string, segmentNumber: number): void {
    let passwordSegment: PasswordSegmentObject = {
      segmentNumber: segmentNumber,
      char: value
    };
    this.passwordSegments.push(passwordSegment);
    this.passwordSegments = _.orderBy(this.passwordSegments, "segmentNumber");
    this.currentSegment = segmentNumber + 1;
    if (this.passwordSegments.length == 5) {
      let user: User | boolean = this.authenticate();
      if (user) {
        this.$emit("authenticated", user);
      } else {
        this.passwordSegments = [];
        this.currentSegment = 1;
        this.shake = true;
        setTimeout(() => {
          this.resetAllFields();
        }, 500);
      }
    }
  }

  protected resetAllFields(): void {
    this.fields.forEach((field: any) => {
      field.reset();
    });
    setTimeout(() => {
      this.fields[0].setFocus();
      this.shake = false;
    }, 500);
  }

  protected authenticate(): User | boolean {
    let passwordString: string = _.map(this.passwordSegments, "char")
      .join("")
      .toString();
    let users: User[] = _.filter(this.users, (user: User) => {
      if (user.password === passwordString) {
        return true;
      }
      return false;
    });

    if (users.length >= 1) {
      return users[0];
    } else {
      return false;
    }
  }

  protected toggleContact(): void {
    if (this.showContact) {
      this.showContact = false;
    } else {
      this.showContact = true;
    }
  }

  public mounted(): void {
    if (this.$refs.fields instanceof Array) {
      this.$refs.fields.forEach((field: any) => {
        this.fields.push(field);
      });
    }
    this.users = Users.users;
  }
}
