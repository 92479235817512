
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { gsap } from "gsap";

@Component
export default class Projects extends Vue {
  @Prop(Number) count!: number;
  protected tweenedCount: number = 0;

  get projectCount(): string {
    return this.tweenedCount.toFixed(0);
  }

  @Watch("count")
  onCountChange(newCount: number, oldCount: number): void {
    gsap.to(this.$data, 0.5, { tweenedCount: newCount });
  }

  public created(): void {
    this.tweenedCount = this.count;
  }
}
