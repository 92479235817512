
import { Component, Vue, Prop } from "vue-property-decorator";
import Radio from "@/components/Radio.vue";

@Component({
  components: {
    Radio
  }
})
export default class RadioGroup extends Vue {
  @Prop(Number) defaultValue!: number;
  protected activeValue: number = 0;

  protected handleActivation(value: number): void {
    this.activeValue = value;
    this.$emit("set", value);
  }
}
