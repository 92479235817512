
import { Component, Vue, Prop } from "vue-property-decorator";
import { SkillsetItemObject } from "@/interfaces";
require("waypoints/lib/noframework.waypoints.js");
declare const Waypoint: any;

@Component
export default class SkillsetItem extends Vue {
  @Prop(Object) skill!: SkillsetItemObject;
  protected visible: boolean = false;
  protected waypoint!: any;

  protected show(): void {
    this.visible = true;
    this.waypoint.destroy();
  }

  public mounted(): void {
    this.waypoint = new Waypoint({
      element: this.$el,
      handler: (direction: string) => {
        this.show();
      },
      offset: "80%"
    });
  }
}
