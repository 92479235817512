var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"c-navigation-sticky"},[_c('ul',{staticClass:"c-navigation-sticky__list"},_vm._l((_vm.navigationItems),function(navigationItem,index){return _c('li',{key:index,staticClass:"c-navigation-sticky__item"},[_c('a',{staticClass:"c-navigation-sticky__link",class:{
          'c-navigation-sticky__link--active':
            index + 1 == _vm.activeNavigationItem
        },attrs:{"href":'#' + navigationItem.link},on:{"click":function($event){$event.preventDefault();return _vm.jumpToTarget(
            navigationItem.link,
            index == 0 ? true : false,
            index + 1 == _vm.navigationItems.length ? true : false
          )}}},[_c('span',{staticClass:"c-navigation-sticky__link__label"},[_vm._v(_vm._s(navigationItem.label))])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }