
import { Component, Vue, Prop } from "vue-property-decorator";
import noUiSlider from "nouislider";
const wNumb = require("wnumb");
import * as _ from "lodash";

@Component
export default class Slider extends Vue {
  @Prop(Number) min!: number;
  @Prop(Number) max!: number;
  @Prop(Number) step!: number;
  @Prop(Number) start!: number;
  @Prop(String) suffix!: string;
  @Prop(Number) density!: number;
  @Prop(String) label!: string;
  protected uiSlider: any;
  protected sliderElement!: HTMLElement;
  public mounted(): void {
    if (this.$refs.sliderElement instanceof HTMLElement) {
      this.sliderElement = this.$refs.sliderElement;
      this.uiSlider = noUiSlider.create(this.sliderElement, {
        range: {
          min: this.min,
          max: this.max
        },
        step: this.step,
        start: [this.start],
        pips: {
          mode: "steps",
          density: this.density,
          format: wNumb({
            decimals: 0,
            suffix: this.suffix
          })
        }
      });
      this.uiSlider.on("set", (value: string) => {
        this.$emit("set", parseInt(value));
      });
    }
  }
}
