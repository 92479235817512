
import { Component, Vue, Prop } from "vue-property-decorator";
import ProjectTile from "@/components/ProjectTile.vue";
import ProjectCount from "@/components/ProjectCount.vue";
import { Category, Project, ProjectYear } from "@/interfaces";
import * as _ from "lodash";

@Component({
  components: {
    ProjectTile,
    ProjectCount
  }
})
export default class Projects extends Vue {
  @Prop(Array) projectObjects!: Project[];
  @Prop(Array) categories!: Category[];
  protected projects: Project[] = [];
  protected activeFilters: number[] = [];

  get filteredProjects(): Project[] {
    if (this.activeFilters.length > 0) {
      return this.projects.filter((project: Project) => {
        let intersection: number[] = _.intersection(
          project.categories,
          this.activeFilters
        );
        if (intersection.length > 0) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      return this.projects;
    }
  }

  get groupedProjects(): ProjectYear[] {
    let years: number[] = this.extractYears(this.filteredProjects);
    let projectYears: ProjectYear[] = [];
    years.forEach((year: number) => {
      let projectsOfYear: Project[] = this.filteredProjects.filter(
        (project: Project) => {
          if (project.year === year) {
            return true;
          } else return false;
        }
      );
      let projectYear: ProjectYear = {
        year: year,
        projects: projectsOfYear
      };
      projectYears.push(projectYear);
    });
    return projectYears;
  }

  protected updateFilter(categoryId: number): void {
    if (this.activeFilters.includes(categoryId)) {
      this.activeFilters.splice(this.activeFilters.indexOf(categoryId), 1);
    } else {
      this.activeFilters.push(categoryId);
    }
  }

  protected extractYears(projects: Project[]): number[] {
    return _.uniq(_.map(projects, "year"));
  }

  protected isCategoryActive(category: Category): boolean {
    if (this.activeFilters.includes(category.id)) {
      return true;
    } else {
      return false;
    }
  }

  public created(): void {
    this.projects = _.orderBy(
      this.projectObjects,
      ["year", "title"],
      ["desc", "asc"]
    );
  }
}
