
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class Radio extends Vue {
  @Prop(Number) value!: number;
  @Prop(Number) activeGroupValue!: number;
  @Prop(String) label!: string;
  @Prop(Boolean) setByDefault!: boolean;
  protected isActive: boolean = this.setByDefault;

  @Watch("activeGroupValue")
  protected watchGroupSelection(value: number) {
    if (value !== this.value) {
      this.isActive = false;
    }
  }

  protected toggle(): void {
    if (!this.isActive) {
      this.isActive = true;
      this.$emit("activate", this.value);
    }
  }
}
