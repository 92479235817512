var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"c-pagehead__nav"},[_c('ul',{staticClass:"c-pagehead__nav__list"},_vm._l((_vm.navigationItems),function(navigationItem,index){return _c('li',{key:index,staticClass:"c-pagehead__nav__item",class:{
        'c-pagehead__nav__item--active': index + 1 == _vm.activeNavigationItem
      }},[_c('a',{staticClass:"c-pagehead__nav__link",attrs:{"href":'#' + navigationItem.link},on:{"click":function($event){$event.preventDefault();return _vm.jumpToTarget(
            navigationItem.link,
            false,
            index + 1 == _vm.navigationItems.length ? true : false
          )}}},[_c('span',[_vm._v(_vm._s(navigationItem.label))])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }