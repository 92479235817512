import { Vue } from "vue-property-decorator";
import { VNode } from "vue";
import { WaypointEventParams } from "@/interfaces";
require("waypoints/lib/noframework.waypoints.js");
declare const Waypoint: any;

Vue.directive("waypoint", {
  inserted: (el: HTMLElement, binding: any, vnode: any) => {
    let sectionNumber: number = 0;
    let offset: string = "50%";
    if (binding.hasOwnProperty("value")) {
      let params: any = binding.value;
      if (params.hasOwnProperty("sectionNumber")) {
        sectionNumber = params.sectionNumber;
      }
      if (params.hasOwnProperty("offset")) {
        offset = params.offset;
      }
    }

    let waypoint = new Waypoint({
      element: el,
      handler: (direction: string) => {
        let eventParams: WaypointEventParams = {
          direction: direction
        };
        if (sectionNumber) {
          eventParams.sectionNumber = sectionNumber;
        }
        let event: CustomEvent = new CustomEvent("waypoint-change", {
          detail: eventParams
        });
        el.dispatchEvent(event);
      },
      offset: offset
    });
  }
});
