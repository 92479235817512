
import { Component, Vue } from "vue-property-decorator";

import Navigation from "./components/Navigation.vue";
import NavigationSticky from "@/components/NavigationSticky.vue";
import Skillset from "./components/Skillset.vue";
import Projects from "@/components/Projects.vue";
import RatesCalculator from "@/components/RatesCalculator.vue";
import CoreCompetences from "@/components/CoreCompetences.vue";
import "./directives/Waypoint.ts";
import SkillsetObjects from "./data/skillsets.json";
import NavigationItemObjects from "./data/navigation.json";
import ProjectObjects from "./data/projects.json";
require("waypoints/lib/noframework.waypoints.js");
declare const Waypoint: any;
require("waypoints/lib/shortcuts/inview.js");
declare const Inview: any;
import {
  Category,
  CoreCompetenceObject,
  NavigationItem,
  Project,
  SkillsetItemObject
} from "@/interfaces";

@Component({
  components: {
    Navigation,
    Skillset,
    Projects,
    NavigationSticky,
    RatesCalculator,
    CoreCompetences
  }
})
export default class App extends Vue {
  protected activeSection: number = 0;
  protected navigationItems: NavigationItem[] = [];
  protected skillsetWebTechnologies: SkillsetItemObject[] = [];
  protected skillsetFrameworks: SkillsetItemObject[] = [];
  protected skillsetToolkits: SkillsetItemObject[] = [];
  protected skillsetSoftware: SkillsetItemObject[] = [];
  protected projects: Project[] = [];
  protected projectCategories: Category[] = [];
  protected pageHead: HTMLElement | null = null;
  protected pageHeadHeight: number = 0;
  protected isNavigationSticky: boolean = false;
  protected coreCompetences: CoreCompetenceObject[] = [];

  get activeNavigationItem(): number {
    return this.activeSection;
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  public created(): void {
    this.navigationItems = NavigationItemObjects.navigationItems;
    this.skillsetWebTechnologies = SkillsetObjects.skillsetWebTechnologies;
    this.skillsetFrameworks = SkillsetObjects.skillsetFrameworks;
    this.skillsetToolkits = SkillsetObjects.skillsetToolkits;
    this.skillsetSoftware = SkillsetObjects.skillsetSoftware;
    this.projects = ProjectObjects.projects;
    this.projectCategories = ProjectObjects.categories;
    this.coreCompetences = SkillsetObjects.coreCompetences;
  }

  public handleSectionWaypointChange(e: CustomEvent): void {
    if (e.detail.direction == "up") {
      this.activeSection = e.detail.sectionNumber - 1;
    } else {
      this.activeSection = e.detail.sectionNumber;
    }
  }

  public mounted(): void {
    if (this.$refs.pageHead instanceof HTMLElement) {
      this.pageHead = this.$refs.pageHead;
      this.pageHeadHeight = this.pageHead.getBoundingClientRect().height;
    }

    let pageHeadInview: any = new Waypoint.Inview({
      element: this.pageHead,
      enter: (direction: any) => {
        this.isNavigationSticky = false;
      },
      exited: (direction: any) => {
        this.isNavigationSticky = true;
      }
    });
    setInterval(() => {
      Waypoint.refreshAll();
    }, 1000);
  }
}
