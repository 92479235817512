
import { Component, Vue, Prop } from "vue-property-decorator";
import { NavigationItem } from "@/interfaces";
import SweetScroll from "sweet-scroll";

@Component
export default class NavigationAbstract extends Vue {
  @Prop(Array) navigationItems!: NavigationItem;
  @Prop(Number) activeNavigationItem!: number;
  protected sweetScroll!: SweetScroll;

  protected jumpToTarget(
    targetIdentifier: string,
    toTop: boolean,
    toBottom: boolean
  ): void {
    if (toTop) {
      this.sweetScroll.to(0);
    } else if (toBottom) {
      let endOfPage: number = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      this.sweetScroll.to(endOfPage);
    } else {
      let target: HTMLElement | null = document.getElementById(
        targetIdentifier
      );
      if (target) {
        this.sweetScroll.toElement(target);
      }
    }
  }

  public created(): void {
    this.sweetScroll = new SweetScroll();
  }
}
