
import { Component, Vue, Prop } from "vue-property-decorator";
import { CoreCompetenceObject } from "@/interfaces";
import CoreCompetence from "@/components/CoreCompetence.vue";

@Component({
  components: {
    CoreCompetence
  }
})
export default class CoreCompetences extends Vue {
  @Prop(Array) coreCompetences!: CoreCompetenceObject;
}
