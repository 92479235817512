
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class PasswordSegment extends Vue {
  @Prop(Number) segmentNumber!: number;
  @Prop(Boolean) currentSegment!: boolean;
  @Prop(Boolean) focus!: boolean;
  protected value: string = "";
  protected input!: HTMLInputElement;
  protected isSet: boolean = false;

  protected handleChange(event: Event): void {
    let value: string = (event.target as HTMLInputElement).value;
    if (value.length > 0) {
      this.isSet = true;
      this.value = value.charAt(0);
      this.$emit("set", this.value, this.segmentNumber);
      this.value = "*";
    } else {
      this.value = "";
    }
  }

  @Watch("focus")
  handleFocusChange(focus: boolean) {
    this.input.focus();
  }

  public reset(): void {
    this.isSet = false;
    this.value = "";
    this.input.blur();
  }

  public setFocus(): void {
    this.input.focus();
  }

  public mounted(): void {
    if (this.$refs.hasOwnProperty("input")) {
      if (this.$refs.input instanceof HTMLInputElement) {
        this.input = this.$refs.input;
      }
    }
    if (!this.input) return;
    if (this.focus) {
      this.input.focus();
    }
  }
}
