
import { Component, Vue, Prop } from "vue-property-decorator";
import Slider from "@/components/Slider.vue";

@Component({
  components: {
    Slider
  }
})
export default class RatesCalculatorCalculationFactors extends Vue {
  @Prop(Number) monthsCountStart!: number;
  @Prop(Number) percentageStart!: number;
  protected monthsCount: number = this.monthsCountStart;
  protected percentage: number = this.percentageStart;

  protected handlePercentageChange(value: number) {
    this.percentage = value;
    this.$emit("change", this.monthsCount, this.percentage);
  }

  protected handleMonthChange(value: number) {
    this.monthsCount = value;
    this.$emit("change", this.monthsCount, this.percentage);
  }
}
