
import { Component, Vue, Prop } from "vue-property-decorator";

import SkillsetItem from "@/components/SkillsetItem.vue";
import { SkillsetItemObject } from "@/interfaces";

@Component({
  components: {
    SkillsetItem
  }
})
export default class Skillset extends Vue {
  @Prop(Array) skills!: SkillsetItemObject[];
}
